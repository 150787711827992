export default {
  title: 'Projects',
  logo: 'folderOpen',
  content: [
    {
      title: 'Behavioral-Interview-Pramp',
      date: '[2022/05]',
      details: ['[Pra]ctice [m]akes [P]erfect! - Behavioral Interview flashcard', 'Use figma to create design and icon'],
      link: {
        title: 'Behavioral-Interview-Pramp',
        href: 'http://behavioral-interview-pramp.vercel.app/',
      },
      projectScreenShot: [
        {
          title: 'Behavioral-Interview-Pramp light mode',
          href: '/projects/bip-light.png',
        },
        {
          title: 'Behavioral-Interview-Pramp dark mode',
          href: '/projects/bip-dark.png',
        },
      ],
    },
    {
      title: 'Drawings labelling tool [Prototype experiment]',
      date: '[2021/12 - 2022/02]',
      details: [
        'A prototype built for a Manufacturing Service startup - Caddi',
        'Can draw labels on PDF drawings that are used by manufacturer',
      ],
    },
    {
      title: 'your-ilfe-in-weeks',
      date: '[2021/07]',
      details: ['Visualize your life in weeks.'],
      link: {
        title: 'your-ilfe-in-weeks',
        href: 'https://github.com/ArvinH/your-ilfe-in-weeks',
      },
      projectScreenShot: [
        {
          title: 'your-ilfe-in-weeks',
          href: 'https://raw.githubusercontent.com/ArvinH/your-ilfe-in-weeks/master/your-life-in-weeks.png',
        },
      ],
    },
    {
      title: 'PRLint-serverless',
      date: '[2020/05]',
      details: ['Serverless github webhook for checking the format of your PR title in favor of standard-version.'],
      link: {
        title: 'PRLint-serverless',
        href: 'https://github.com/ArvinH/PRLint-serverless',
      },
    },
    {
      title: 'shortcuts-vconsole',
      date: '[2020/04]',
      details: ['iOS shortcuts to inject vConsole to pages'],
      link: {
        title: 'shortcuts-vconsole',
        href: 'https://github.com/ArvinH/shortcuts-vconsole',
      },
      projectScreenShot: [
        {
          title: 'shortcuts-vconsol',
          href: 'https://github.com/ArvinH/shortcuts-vconsole/raw/master/statics/ios-shortcuts-js-demo.gif',
        },
      ],
    },
    {
      title: 'claps-button',
      date: '[2020/04]',
      details: ['web component powered by stenciljs. A button for claps.'],
      link: {
        title: 'claps-button',
        href: 'https://github.com/ArvinH/claps-button',
      },
      projectScreenShot: [
        {
          title: 'claps-button',
          href: 'https://github.com/ArvinH/claps-button/raw/master/statics/web-component-claps.gif',
        },
      ],
    },
    {
      title: 'reapptx',
      date: '[2020/03]',
      details: ['A small prototype of custom react renderer, let you render React component to pptx files.'],
      link: {
        title: 'reapptx',
        href: 'https://github.com/ArvinH/reapptx',
      },
    },
    {
      title: 'Elm-ChromeExt',
      date: '[2019/04]',
      details: [
        "This experimental project demonstrates a Chrome extension composed of three Elm applications that communicate through Chrome's message passing.",
      ],
      link: {
        title: 'Elm-ChromeExt',
        href: 'https://github.com/ArvinH/Elm-ChromeExt',
      },
    },
    {
      title: 'react-vimeouploadr',
      date: '[2018/04]',
      details: ['React component for upload video to your vimeo account.', 'Implemented with react/redux/vimeo api.'],
      link: {
        title: 'react-vimeouploadr',
        href: 'https://github.com/ArvinH/react-vimeouploadr',
      },
      projectScreenShot: [
        {
          title: '',
          href: '/projects/react-vimeouploadr.png',
        },
      ],
    },
    {
      title: 'acss_devtool',
      date: '[2018/02]',
      details: [
        'Chrome devtool extension for Atomic CSS. Turn your element style into Atomic CSS class automatically. I built this for improving my performance in daily works.',
        'Implemented with atomizer and chrome devtool extension api.',
      ],
      link: {
        title: 'acss_devtool',
        href: 'https://github.com/ArvinH/acss_devtool',
      },
      projectScreenShot: [
        {
          title: '',
          href: '/projects/acss-devtool.png',
        },
      ],
    },
    {
      title: 'atomic-css-search',
      date: '[2017/11]',
      details: [
        'VSCode extension for searching atomic CSS class name. I built this for improving my performance in daily works. Tools I used: atomizer, vscode, nodejs',
        'Implemented with atomizer and VSCode extension api.',
      ],
      link: {
        title: 'atomic-css-search',
        href: 'https://github.com/ArvinH/atomic-css-search',
      },
      projectScreenShot: [
        {
          title: '',
          href: '/projects/acss-search.png',
        },
      ],
    },
    {
      title: 'Emotion live player (Yahoo TWxJP Joint Hack)',
      date: '[2016/12]',
      details: [
        'Emotion detected on live streaming meeting room.',
        'This demo project built for 2016 Yahoo TWxJP joint hack day, it likes a video conference site that host can see the emotions from other attendees, so host can know how his/her did from those feedback. We caculate the emotion score anonymously and show it in timeline with animated bubble format.',
        'Tool sets: #create-react-app, #Microsoft Cloud API, #Firebase',
      ],
      projectScreenShot: [
        {
          title: '',
          href: '/projects/emo-live-yahooTWxJP.gif',
        },
      ],
    },
  ],
};
