export default {
  title: 'Contest',
  logo: 'trophy',
  content: [
    {
      title: '2020 Mercari Autumn Hack Day',
      date: '[2020/10]',
      details: [
        'Implement an automatic banner generator that can build multiple version of campaign banners',
        'Implement a 3D Mercari Logo by using three.js',
      ],
    },
    {
      title: '2020 Mercari Spring Hack Day',
      date: '[2020/03]',
      details: ['Implement a static page builder that can support component library'],
    },
    {
      title: '2018 Oath Global Hack Day',
      date: '[2018/02]',
      details: [
        'Design and implement a messenger bot that integrate with one of our existing products and got the approve from manager that can become an offical product.',
        'Implement a chrome devtool extension for our internal front-end framework and received positive responses from the core-team member in the US.',
      ],
    },
    {
      title: '2016 Yahoo TW/JP Joint Hack Day in Taiwan',
      date: '[2016/12]',
      details: [
        'Use Microsoft Cloud API & React & Firebase to implement a emotion detect meeting service, which let you have the ability to know all audiences emotion during the meeting.',
      ],
    },
    {
      title: 'Pepper developer contest, Champion',
      date: '[2016/08 - 2016/09]',
      details: [
        'Integrate brain wave detection(EEG) with Pepper, develop an application on Pepper that let Pepper can interact with customers and give customers personalized service by detected their preference of different products.',
      ],
    },
    {
      title: '2016 Yahoo TW Bot Challenge',
      date: '[2016/07]',
      details: [
        'Use Messenger API to implement a chatbot that can create bot for stores, customer can draw a number plate from bot we create, stores can dispatch advertisement or coupon to customer. No more waiting line, no more wasted waiting time!',
      ],
    },
    {
      title: '2012 Yahoo Open Hack Day in Taiwan',
      date: '[2012/10]',
      details: [
        'Combined Flickr api and google api to create a “Photo recommended website”, based on LBS and spatialtemporal data filtering.',
      ],
    },
    {
      title: '2012 Evernote Hackathon',
      date: '[2012/07]',
      details: ['Used Evernote api, PayPal api and Kickstrapter framework to create an “Online Notes Trading Platform” in 30 hours.'],
    },
    {
      title: '6th Center for Talented Youth, Venture Capital Competition, Champion',
      date: '[2011/07]',
      details: ['Project name:“Sweet Shopping”—bound augmented reality and store information.'],
    },
  ],
};
