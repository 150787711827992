export default {
  title: 'Experience',
  logo: 'fileAlt',
  content: [
    {
      title: 'Frontend engineer & Tech Lead, Mercari',
      date: '[2019/02-]',
      details: [
        'Lead frontend team (3 ppl) collaborate with mobile team to design and implement a renewal native-bridge library to be used across the company, it provides a better error tracking system and is more secure compare to the previous version, significantly decrease the error rate to less than 0.6% [Typescript]',
        `Work with one teammate to create several internal developer tools to increase the team's productivity, such as cli tool and testing tool for webview [Typescript, Bash, Nodejs, appium, webdriver]`,
        'Lead a team (4 ppl) to implement an internal tool for sending push notification to users [React, GCP, K8S]',
        'Lead a team (4 ppl) to refactor the existing CMS to support Server-side pre-rendered campaign page, also let our designer can create a campaign page easily by compositing various components. [React, Nodejs, Redis, GCP]',
        'Help re-build a customer service tool with cutting-edge technology [React, Apollo, GraphQL]',
        'Design and Implement a server-less CMS for creating campaign page [React, GCP]',
        'Implement several campaign pages in app webview [React]',
      ],
    },
    {
      title: 'Frontend engineer, Yahoo',
      date: '[2015/12-2019/01]',
      details: [
        'Create an interactive topic discussion platform called PK擂台 for TW/HK Yahoo News site that users can choose and discuss their opinions in real time (PC/Mobile) [React/Flux].',
        'Implement a modularized poll application that been used in various properties across Yahoo (PC) [React/Flux]',
        'Proactively create a dynamic typhoon path-map by using D3.js and React which achieved one million page view on that topic project (PC/Mobile) [React/Flux]',
        'Design and implement a novel card-typed reading experience with parallax scrolling effect on the mobile web. [React/Flux]',
        'Design and construct an editorial tool with real-time preview function for our news editors/PGC users to create Youcard content. It also supports Excel-like experience to create charts by using React and D3.js. [React/Flux]',
        'Improved web performance include reducing loading time and bundle size, making it 5x faster. [Webpack/Grunt/Javascript]',
        'Implement and launch first AMP site in Yahoo TW Media team, significantly increase the user experience for our mobile users. [AMP]',
        'Implement a chrome devtool extension for our internal front-end framework and received positive responses from the core-team member in the US. [Javascript]',
        'MVP award in 2016 Q2',
      ],
      projectScreenShot: [
        {
          title: 'Yahoo News',
          href: '/projects/yahoo-news.png',
        },
        {
          title: 'Typhoon map',
          href: '/projects/Typhoon-path-map.png',
        },
        {
          title: 'PK擂台',
          href: '/projects/yahoo-pk-mobile.gif',
        },
        {
          title: 'Y 民調',
          href: '/projects/yahoo-poll.png',
        },
        {
          title: 'Yahoo 懶人卡',
          href: '/projects/yahoo-youcard-mobile.png',
        },
        {
          title: '',
          href: '/projects/yahoo-youcard-content-pc.png',
        },
      ],
    },
    {
      title: 'Intern, TrendMicro',
      date: '[2013/07-2013/08]',
      details: ['Emerging Threat Sourcing Evaluation.', 'System Design by using Graph DB (Neo4j).', 'Performance Evaluation.'],
    },
    {
      title: 'Intern, Patisco.com, Xinosys co., ltd--a B2B web-based commercial platform',
      date: '[2012/07-2012/08]',
      details: [
        'Used Apache Solr to help patisco.com build up a full-text search engine.',
        'Used WebSocket + Redis + akka to help patisco.com build up a internal Pub/Sub system.',
      ],
    },
  ],
};
