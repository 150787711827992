import experiences from './experiences';
import contest from './contest';
import projects from './projects';

export default [
  {
    ...experiences,
  },
  {
    ...projects,
  },
  {
    ...contest,
  },
];
