import React from 'react';
import Helmet from 'react-helmet';
import { Link } from 'gatsby';
import { Layout, Wrapper, Header, Button, Content, SectionTitle, ResumeBlock } from '../components';
import config from '../../config/SiteConfig';
import PageProps from '../models/PageProps';
import resume from '../data/resume';

export default class AboutPage extends React.Component<PageProps> {
  public render() {
    return (
      <Layout>
        <Helmet title={`About | ${config.siteTitle}`} />
        <Header>
          <Link to="/">{config.siteTitle}</Link>
          <SectionTitle uppercase={true}>About Me</SectionTitle>
        </Header>
        <Wrapper>
          <Content>
            <h2>Hi everyone,</h2>
            <p>
              I am passionate about creating the fascinating visual experience and solving real-world problems by using technical skills and
              tools.
            </p>
            <p>
              As a professional developer in web front-end area, I have 4+ years of experiences and love to build up robust, appealing,
              secure and accessible websites.
            </p>
            <p>
              Driven by the curiosity and passion for new technologies, I constantly investigate cutting-edge technologies and document it
              on my blog, also act as an evangelist to others by operating a tech weekly newsletter which has more than 5,000 subscribers.
            </p>
            <p>
              As an amateur developer of data engineering and data visualization, I construct several data visualization works on my blog
              and shared by newsletter.
            </p>
            <p>In case you want to find me...</p>
            <a href="https://twitter.com/ArvinHv">
              <Button big>
                <svg width="1792" height="1792" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1764 11q33 24 27 64l-256 1536q-5 29-32 45-14 8-31 8-11 0-24-5l-453-185-242 295q-18 23-49 23-13 0-22-4-19-7-30.5-23.5t-11.5-36.5v-349l864-1059-1069 925-395-162q-37-14-40-55-2-40 32-59l1664-960q15-9 32-9 20 0 36 11z" />
                </svg>
                Twitter
              </Button>
            </a>
            <a href="https://www.linkedin.com/in/arvinhv/">
              <Button big>
                <svg width="1792" height="1792" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1764 11q33 24 27 64l-256 1536q-5 29-32 45-14 8-31 8-11 0-24-5l-453-185-242 295q-18 23-49 23-13 0-22-4-19-7-30.5-23.5t-11.5-36.5v-349l864-1059-1069 925-395-162q-37-14-40-55-2-40 32-59l1664-960q15-9 32-9 20 0 36 11z" />
                </svg>
                Linkedin
              </Button>
            </a>
            <a href="https://github.com/ArvinH">
              <Button big>
                <svg width="1792" height="1792" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1764 11q33 24 27 64l-256 1536q-5 29-32 45-14 8-31 8-11 0-24-5l-453-185-242 295q-18 23-49 23-13 0-22-4-19-7-30.5-23.5t-11.5-36.5v-349l864-1059-1069 925-395-162q-37-14-40-55-2-40 32-59l1664-960q15-9 32-9 20 0 36 11z" />
                </svg>
                GitHub
              </Button>
            </a>
          </Content>
          {resume.map(data => (
            <ResumeBlock key={data.title} {...data} />
          ))}
        </Wrapper>
      </Layout>
    );
  }
}
